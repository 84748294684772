import { isValidPhoneNumber, restrictedPrefixes } from '@/utils/Constants';
import { z } from 'zod';


const postalCodeRegex = /^[A-Za-z0-9\s\-]*$/; // Simple alphanumeric with space and hyphen
const zipCodeRegex = /^\d{5}(-\d{4})?$/; // US ZIP and ZIP+4
const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^&()\-_+={[}\]|:;'"<>,.!?@#$%^*`~\\/])[A-Za-z\d\^&()\-_+={[}\]|:;'"<>,.!?@#$%^*`~\\/]{8,}$/;

export const loginSchema = z.object({
  username: z
    .string()
    .min(1, { message: 'Required' })
    .max(100, { message: 'Maximum 100 Characters is allowed' }),
  password: z
    .string()
    .min(1, { message: 'Required' })
    .max(50, { message: 'Maximum 50 Characters is allowed' }),
});

export const resetPasswordSchema = z
  .object({
    password: z
      .string()
      .min(1, { message: 'Required' })
      .max(50, { message: 'Maximum 50 Characters is allowed' })
      .regex(passwordRegex, {
        message: 'Password must contain 1 upper, 1 lower, 1 number and 1 special char.',
      }),
    confirmPassword: z
      .string()
      .min(1, { message: 'Required' })
      .max(50, { message: 'Maximum 50 Characters is allowed' }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Passwords do not match',
    path: ['confirmPassword'],
  });

export const forgetPasswordSchema = z.object({
  username: z
    .string()
    .min(1, { message: 'Required' })
    .max(50, { message: 'Maximum 50 Characters is allowed' }),
});

export const raidSchema = z.object({
  name: z
    .string()
    .min(1, { message: 'Required' })
    .max(250, { message: 'Maximum 250 Characters is allowed' }),
  union: z
    .string()
    .min(1, { message: 'Required' })
    .max(250, { message: 'Maximum 250 Characters is allowed' }),
  agreementLink: z
    .string()
    .min(1, { message: 'Required' })
    .regex(/^(http|https):\/\//, {
      message: 'Invalid URL',
    }),
  effectiveDate: z.date().superRefine((value, ctx) => {
    if (value == null) {
      ctx.addIssue({
        code: 'custom',
        message: 'Required',
      });
    }
  }),
  expiryDate: z.date().superRefine((value, ctx) => {
    if (value == null) {
      ctx.addIssue({
        code: 'custom',
        message: 'Required',
      });
    }
  }),
});

export const apiKeyAddSchema = z.object({
  name: z
    .string()
    .min(1, { message: 'Required' })
    .max(50, { message: 'Maximum 50 Characters is allowed' }),
  keyType: z
    .string()
    .min(1, { message: 'Required' })
    .max(50, { message: 'Maximum 50 Characters is allowed' }),
});

export const accountSchema = z.object({
  firstName: z
    .string()
    .min(1, { message: 'Required' })
    .max(75, { message: 'Maximum 75 Characters is allowed' }),
  lastName: z
    .string()
    .min(1, { message: 'Required' })
    .max(75, { message: 'Maximum 75 Characters is allowed' }),
  email: z
    .string()
    .min(1, { message: 'Required' })
    .max(100, { message: 'Maximum 100 Characters is allowed' })
    .email({ message: 'Wrong Format' }),
  enableSetting: z.boolean().optional(),
  enableAccount: z.boolean().optional(),
  enableDrive: z.boolean().optional(),
  enableReport: z.boolean().optional(),
  enableAgreement: z.boolean().optional(),
  enableBilling: z.boolean().optional(),
  enableOESytem: z.boolean().optional(),
  enableOwner: z.boolean().optional(),
  enableOrganizerManager: z.boolean().optional(),
  enableSMS: z.boolean().optional(),
  enableCreateSMS: z.boolean().optional(),
  enableApproveSMS: z.boolean().optional(),
  cellPhone: z
    .string()
    .refine((val) => isValidPhoneNumber(val), {
      message: 'Invalid Phone Number',
    })
    .optional()
    .or(z.literal('')),
});

export const accountUpdateSchema = z.object({
  firstName: z
    .string()
    .min(1, { message: 'Required' })
    .max(50, { message: 'Maximum 50 Characters is allowed' }),
  lastName: z
    .string()
    .min(1, { message: 'Required' })
    .max(50, { message: 'Maximum 50 Characters is allowed' }),
  email: z
    .string()
    .min(1, { message: 'Required' })
    .max(100, { message: 'Maximum 100 Characters is allowed' })
    .email({ message: 'Wrong Format' }),
  // password: z
  //   .string()
  //   .max(50, { message: "Maximum 50 Characters is allowed" }),
  // confirmPassword: z
  //   .string()
  //   .max(50, { message: "Maximum 50 Characters is allowed" }),
  enableSetting: z.boolean().optional(),
  enableAccount: z.boolean().optional(),
  enableDrive: z.boolean().optional(),
  enableReport: z.boolean().optional(),
  enableRaid: z.boolean().optional(),
  enableBilling: z.boolean().optional(),
  enableOwner: z.boolean().optional(),
  enableSMS: z.boolean().optional(),
  enableCreateSMS: z.boolean().optional(),
  enableApproveSMS: z.boolean().optional(),
  enableTouchPoint: z.boolean().optional(),
  enableTouchPointAdmin: z.boolean().optional(),
  cellPhone: z
    .string()
    .refine((val) => isValidPhoneNumber(val), {
      message: 'Invalid Phone Number',
    })
    .optional()
    .or(z.literal('')),
});
// .refine((data) => data.password === data.confirmPassword, {
//   message: "Passwords do not match",
//   path: ["confirmPassword"],
// });

export const updateNotificationType = z.object({
  peopleAssigned: z.boolean().optional(),
  cardSigned: z.boolean().optional(),
  email: z.boolean().optional(),
  sms: z.boolean().optional(),
  pushNotification: z.boolean().optional(),
});

export const updateProfileSchema = z.object({
  firstName: z
    .string()
    .min(1, { message: 'Required' })
    .max(50, { message: 'Maximum 50 Characters is allowed' }),
  lastName: z
    .string()
    .min(1, { message: 'Required' })
    .max(50, { message: 'Maximum 50 Characters is allowed' }),
  email: z
    .string()
    .min(1, { message: 'Required' })
    .max(100, { message: 'Maximum 100 Characters is allowed' })
    .email({ message: 'Wrong Format' }),
  password: z
    .string()
    .max(50, { message: 'Maximum 50 Characters is allowed' })
    .regex(passwordRegex, {
      message: 'Password must contain 1 upper, 1 lower, 1 number and 1 special char.',
    }),
  confirmPassword: z
    .string()
    .max(50, { message: 'Maximum 50 Characters is allowed' })
    .regex(passwordRegex, {
      message: 'Password must contain 1 upper, 1 lower, 1 number and 1 special char.',
    }),
  cellPhone: z
    .string()
    .refine((val) => isValidPhoneNumber(val), {
      message: 'Invalid Phone Number',
    })
    .optional()
    .or(z.literal('')),
});

export const targetSchema = z.object({
  companyName: z
    .string()
    .min(1, { message: 'Required' })
    .max(50, { message: 'Maximum 50 Characters is allowed' }),
  estimatedEmployees: z
    .number()
    .lte(1000000, { message: 'Maximum employee should be 1000000' })
    .optional()
    .or(z.literal('')),
  street1: z.string().min(0).max(150, { message: 'Maximum 150 Characters is allowed' }),
  street2: z.string().min(0).max(150, { message: 'Maximum 150 Characters is allowed' }),
  postalCode: z.string().max(10, { message: 'Maximum 10 Characters is allowed' }),
  city: z.string().max(150, { message: 'Maximum 150 Characters is allowed' }),
  provinceId: z.string().min(1, { message: 'Required' }).max(250, { message: 'Maximum 250 Characters is allowed' }),
});

export const peopleFormSchema = z.object({
  firstName: z
    .string()
    .min(1, { message: 'Required' })
    .max(50, { message: 'Maximum 50 Characters is allowed' }),
  lastName: z
    .string()
    .min(1, { message: 'Required' })
    .max(50, { message: 'Maximum 50 Characters is allowed' }),
  phoneNumber: z
    .string()
    .refine((val) => isValidPhoneNumber(val), {
      message: 'Invalid Phone Number',
    })
    .refine(
      (phone) => !restrictedPrefixes.some((prefix) => phone.startsWith(prefix)),
      { message: 'Invalid Phone Number' }
    )
    .optional()
    .or(z.literal('')),
  registrationNumber: z
    .string()
    .min(0)
    .max(50, { message: 'Maximum 50 Characters is allowed' })
    .optional(),
  unionLocal: z.string().min(0).max(50, { message: 'Maximum 50 Characters is allowed' }).optional(),
  position: z.string().min(0).max(50, { message: 'Maximum 50 Characters is allowed' }).optional(),
  currentEmployee: z.boolean().optional(),
  unionStatus: z.string().max(50, { message: 'Maximum 50 Characters is allowed' }),
  street1: z.string().min(0).max(250, { message: 'Maximum 250 Characters is allowed' }),
  street2: z.string().min(0).max(250, { message: 'Maximum 250 Characters is allowed' }),
  provinceId: z
    .string()
    .max(250, { message: 'Maximum 250 Characters is allowed' })
    .optional(),
  postalCode: z
    .string()
    .max(10, { message: 'Maximum 250 Characters is allowed' })
    .optional(),
  city: z.string().min(0).max(250, { message: 'Maximum 250 Characters is allowed' }),
  countryCode: z.string().min(1, { message: 'Required' }),
  personStateId: z
    .string()
    .min(1, { message: 'Required' })
    .max(250, { message: 'Maximum 250 Characters is allowed' }),
  driveId: z
    .string()
    .min(1, { message: 'Required' })
    .max(250, { message: 'Maximum 250 Characters is allowed' }),
});

export const emailMandatoryFormSchema = z.object({
  email: z.string().min(0).max(100, { message: 'Maximum 100 Characters is allowed' }).email(),
});

export const emailOptionalFormSchema = z.object({
  email: z
    .string()
    .max(100, { message: 'Maximum 100 Characters is allowed' })
    .email()
    .optional()
    .or(z.literal('')),
});

export const driveExportReportSchema = z.object({
  driveName: z
    .string()
    .min(1, { message: 'Required' })
    .max(250, { message: 'Maximum 250 Characters is allowed' }),
  notes: z.boolean().optional(),
  cards: z.boolean().optional(),
  people: z.boolean().optional(),
  companies: z.boolean().optional(),
});

export const addNoteSchema = z.object({
  isPrivate: z.boolean(),
  userAccess: z.string().optional().array().min(0),
  driveId: z
    .string()
    .min(1, { message: 'Required' })
    .max(250, { message: 'Maximum 250 Characters is allowed' }),
  noteDate: z
    .date()
    .nullable()
    .superRefine((value, ctx) => {
      if (value == null) {
        ctx.addIssue({
          code: 'custom',
          message: 'Required',
        });
      }
    }),
  noteTime: z.string().min(1, { message: 'Required' }),
  comment: z
    .string()
    .min(1, { message: 'Required' })
    .max(5000, { message: 'Maximum 5000 Characters is allowed' }),
  // personSearch: z.string().max(250, { message: 'Maximum 250 Characters is allowed' }),
  // showOESystemSearch: z.boolean(),
  firstName: z
    .string()
    .min(1, { message: 'Required' })
    .max(50, { message: 'Maximum 50 Characters is allowed' }),
  lastName: z
    .string()
    .min(1, { message: 'Required' })
    .max(50, { message: 'Maximum 50 Characters is allowed' }),
  registrationNumber: z.string().max(50, { message: 'Maximum 50 Characters is allowed' }),
  position: z.string().min(0).max(50, { message: 'Maximum 50 Characters is allowed' }),
  phoneNumber: z
    .string()
    .refine((val) => isValidPhoneNumber(val), {
      message: 'Invalid Phone Number',
    })
    .optional()
    .or(z.literal('')),
  email: z
    .string()
    .min(0, { message: 'Required' })
    .max(100, { message: 'Maximum 100 Characters is allowed' })
    .email()
    .optional()
    .or(z.literal('')),
  currentEmployee: z.boolean(),
  unionLocal: z.string().min(0).max(50, { message: 'Maximum 50 Characters is allowed' }).optional(),
  unionStatus: z
    .string()
    .min(0)
    .max(50, { message: 'Maximum 50 Characters is allowed' })
    .optional(),
  personStateId: z
    .string()
    .min(1, { message: 'Required' })
    .max(250, { message: 'Maximum 250 Characters is allowed' }),
  // files: z.string(),
});

export const addNotePersonSchema = z.object({
  isPrivate: z.boolean(),
  userAccess: z.string().optional().array().min(0),
  comment: z
    .string()
    .min(1, { message: 'Required' })
    .max(5000, { message: 'Maximum 5000 Characters is allowed' }),
});

export const cardExportSchemaReportSchema = z.object({
  driveName: z
    .string()
    .min(1, { message: 'Required' })
    .max(250, { message: 'Maximum 250 Characters is allowed' }),
});

export const expiryCardReportSchema = z.object({
  expiryDate: z
    .date()
    .nullable()
    .superRefine((value, ctx) => {
      if (value == null) {
        ctx.addIssue({
          code: 'custom',
          message: 'Required',
        });
      }
    }),
  driveName: z
    .string()
    .min(1, { message: 'Required' })
    .max(250, { message: 'Maximum 250 Characters is allowed' }),
});

export const mapViewSchema = z.object({
driveList:z.string()
.min(1, { message: 'Required' })
.max(250, { message: 'Maximum 250 Characters is allowed' }),
});


export const driveSchema = z.object({
  name: z
    .string()
    .min(1, { message: 'Required' })
    .max(50, { message: 'Maximum 50 Characters is allowed' }),
  startDate: z.date().superRefine((value, ctx) => {
    if (value == null) {
      ctx.addIssue({
        code: 'custom',
        message: 'Required',
      });
    }
  }),
  companyId: z.string().min(1, { message: 'Required' }),
  costOfDrive: z.number().min(0),
  signatureDeclarationText: z
    .string()
    .min(1, { message: 'Required' })
    .max(4000, { message: 'Maximum 4000 Characters is allowed' }),
  driveAccessedBy: z.string().optional().array().min(0),
  tenantSMSPhoneNumberId: z.string().optional().nullable(),
});

export const organizersSchema = z.object({
  organizers: z.string()
  .min(1, { message: 'Required please select organizer' })
  .max(101, { message: 'Maximum 101 Characters is allowed' }),
});
export const SignUpSchema = z.object({
  firstName: z
    .string()
    .min(1, { message: 'First name is required' })
    .max(50, { message: 'First name: maximum 50 characters allowed' }),

  lastName: z
    .string()
    .min(1, { message: 'Last name is required' })
    .max(50, { message: 'Last name: maximum 50 characters allowed' }),

  phoneNumber: z
    .string()
    .min(1, { message: 'Phone number is required' })
    .refine((val) => isValidPhoneNumber(val), {
      message: 'Invalid Phone Number',
    }),

  email: z
    .string()
    .min(1, { message: 'Email is required' })
    .email({ message: 'Invalid email format' })
    .max(100, { message: 'Email: maximum 100 characters allowed' }),

  street1: z
    .string()
    .min(1, { message: 'Street address is required' })
    .max(100, { message: 'Street address: maximum 100 characters allowed' }),

  street2: z
    .string()
    .max(100, { message: 'Street address: maximum 100 characters allowed' })
    .optional(),

  city: z
    .string()
    .min(1, { message: 'City is required' })
    .max(50, { message: 'City: maximum 50 characters allowed' }),

  provinceId: z.string().min(1, { message: 'Province is required' }),

  postalCode: z
    .string()
    .min(1, { message: 'Postal code is required' })
    .regex(postalCodeRegex, { message: 'Invalid postal code format' })
    .max(10, { message: 'Postal code must be no more than 10 characters' }),

  zipCode: z
    .string()
    .regex(zipCodeRegex, { message: 'Invalid zip code format' })
    .max(10, { message: 'Zip code must be no more then 10 characters' })
    .optional(),

  signatureURL: z.string().optional(), // Signature validation is handled separately in the component
});

export const campaignSchemaCreate = z.object({
  title: z
    .string()
    .min(1, { message: 'Required' })
    .max(100, { message: 'Maximum 100 Characters is allowed' }),
  driveId: z.string().min(1).max(250, { message: 'Maximum 250 Characters is allowed' }),
  messageTemplateId: z.string().optional(),
  messageText: z
    .string()
    .min(1, { message: 'Required' })
    .max(1530, { message: 'Maximum 1530 Characters is allowed' }),
  saveTemplate: z.boolean().optional(),
  selectedMedia: z.string().optional().array().min(0),
});

export const campaignSchemaEdit = z.object({
  title: z
    .string()
    .min(1, { message: 'Required' })
    .max(100, { message: 'Maximum 100 Characters is allowed' }),
  driveId: z.string().min(1).max(250, { message: 'Maximum 250 Characters is allowed' }),
  messageTemplateId: z.string().optional(),
  messageText: z
    .string()
    .min(1, { message: 'Required' })
    .max(1530, { message: 'Maximum 1530 Characters is allowed' }),
  action: z.string().optional().nullable(),
  isScheduled: z.boolean().optional(),
  scheduleTime: z.any().optional(),
  saveTemplate: z.boolean().optional(),
  sendToOwners: z.boolean().optional(),
  finalize: z.boolean().optional(),
  selectedMedia: z.string().optional().array().min(0),
});

export const brandingSchema = z.object({
  contactEmail: z
    .string()
    .min(0)
    .max(50, { message: 'Maximum 100 Characters is allowed' })
    .email({ message: 'Wrong Format' })
    .optional()
    .or(z.literal('')),
  contactPhone: z
    .string()
    .min(1, { message: 'Required' })
    .max(50, { message: 'Maximum 50 Characters is allowed' }),
  signEmailSubject: z
    .string()
    .min(1, { message: 'Required' })
    .max(200, { message: 'Maximum 200 Characters is allowed' })
    .optional(),
  signatureDeclarationText: z
    .string()
    .min(1, { message: 'Required' })
    .max(4000, { message: 'Maximum 4000 Characters is allowed' })
    .optional(),
  footerCaption: z
    .string()
    .min(0)
    .max(100, { message: 'Maximum 100 Characters is allowed' })
    .optional(),
  headerCaption1: z
    .string()
    .min(0)
    .max(40, { message: 'Maximum 40 Characters is allowed' })
    .optional(),
  headerCaption2: z
    .string()
    .min(0)
    .max(40, { message: 'Maximum 40 Characters is allowed' })
    .optional(),
  headerCaption3: z
    .string()
    .min(0)
    .max(40, { message: 'Maximum 40 Characters is allowed' })
    .optional(),
  step1Title: z.string().min(0).max(30, { message: 'Maximum 30 Characters is allowed' }).optional(),
  step1Description: z
    .string()
    .min(0)
    .max(500, { message: 'Maximum 500 Characters is allowed' })
    .optional(),
  step1Instruction: z
    .string()
    .min(0)
    .max(50, { message: 'Maximum 50 Characters is allowed' })
    .optional(),
  step2Title: z.string().min(0).max(30, { message: 'Maximum 30 Characters is allowed' }).optional(),
  step2Description: z
    .string()
    .min(0)
    .max(500, { message: 'Maximum 500 Characters is allowed' })
    .optional(),
  step2Instruction: z
    .string()
    .min(0)
    .max(50, { message: 'Maximum 50 Characters is allowed' })
    .optional(),
  step3Title: z.string().min(0).max(30, { message: 'Maximum 30 Characters is allowed' }).optional(),
  step3Description: z
    .string()
    .min(0)
    .max(500, { message: 'Maximum 500 Characters is allowed' })
    .optional(),
  step3Instruction: z
    .string()
    .min(0)
    .max(50, { message: 'Maximum 50 Characters is allowed' })
    .optional(),
  subTitle1: z.string().min(0).max(70, { message: 'Maximum 70 Characters is allowed' }).optional(),
  subTitle2: z.string().min(0).max(70, { message: 'Maximum 70 Characters is allowed' }).optional(),
  bodyHeader: z.string().min(0).max(50, { message: 'Maximum 50 Characters is allowed' }).optional(),
  bodyDescription: z
    .string()
    .min(0)
    .max(500, { message: 'Maximum 500 Characters is allowed' })
    .optional(),
  signatureTitleUseDriveName: z.boolean(),
  stripeApiKey: z
    .string()
    .min(0)
    .max(200, { message: 'Maximum 200 Characters is allowed' })
    .optional(),
  stripeSecretKey: z
    .string()
    .min(0)
    .max(200, { message: 'Maximum 200 Characters is allowed' })
    .optional(),
  webhookKey: z
    .string()
    .min(0)
    .max(200, { message: 'Maximum 200 Characters is allowed' })
    .optional(),
  localShortName: z
    .string()
    .min(0)
    .max(200, { message: 'Maximum 200 Characters is allowed' })
    .optional(),
  localLongName: z
    .string()
    .min(0)
    .max(200, { message: 'Maximum 200 Characters is allowed' })
    .optional(),
  localURL: z
    .string()
    .min(1, { message: 'Required' })
    .max(200, { message: 'Maximum 200 Characters is allowed' }),
  cardValidFor: z
    .number()
    .gte(0, { message: 'Required' })
    .lte(1000, { message: 'Maximum value should be 1000' }),
  cardValidForUnit: z.string().min(0).max(50, { message: 'Maximum 50 Characters is allowed' }),
  smsPhone: z.string().min(0).max(100, { message: 'Maximum 100 Characters is allowed' }).optional(),
  smssid: z.string().min(0).max(100, { message: 'Maximum 100 Characters is allowed' }).optional(),
  smsServiceSID: z
    .string()
    .min(0)
    .max(100, { message: 'Maximum 100 Characters is allowed' })
    .optional(),
  smsToken: z.string().min(0).max(100, { message: 'Maximum 100 Characters is allowed' }).optional(),
  smsSegmentCost: z.number().min(0),
  mmsSegmentCost: z.number().min(0),
  tenantTimeZone: z
    .string()
    .min(1, { message: 'Required' })
    .max(100, { message: 'Maximum 100 Characters is allowed' }),
});

export const conversationComponentSchema = z.object({
  messageText: z.string().min(1).max(500, { message: 'Maximum 500 Characters is allowed' }),
  mediaLibraryId: z.string().optional(),
  selectedMedia: z.string().optional().array().min(0),
});

export const messageTemplateSchema = z.object({
  messageText: z
    .string()
    .min(1, { message: 'Required' })
    .max(1530, { message: 'Maximum 1530 Characters is allowed' }),
  selectedMedia: z.string().optional().array().min(0),
});

export const smsPhoneNumberSchema = z.object({
  phoneNumber: z
    .string()
    .refine((val) => isValidPhoneNumber(val), {
      message: 'Invalid Phone Number',
    }),
  description: z.string().max(50, { message: 'Maximum 50 Characters is allowed' }).optional(),
});

export const touchPointSchema = z.object({
  driveId: z
    .string()
    .min(1, { message: 'Required' })
    .max(250, { message: 'Maximum 250 Characters is allowed' }),
  touchPointTypeId: z
    .string()
    .min(1, { message: 'Required' })
    .max(250, { message: 'Maximum 250 Characters is allowed' }),
  details: z
    .string()
    .min(1, { message: 'Required' })
    .max(4000, { message: 'Maximum 4000 Characters is allowed' }),
  remarks: z
    .string()
    .max(4000, { message: 'Maximum 4000 Characters is allowed' })
    .optional()
    .or(z.literal('')),
  firstName: z.string().optional().or(z.literal('')),
  lastName: z.string().optional().or(z.literal('')),
  phoneNumber: z.string().optional().or(z.literal('')),
  email: z.string().email().optional().or(z.literal('')),
  signStateID: z.union([z.string(), z.number(), z.literal('')]).optional(),
  sentimentID: z.union([z.string(), z.number(), z.literal('')]).optional(),
});

export const cardSignedSchema = z.object({
  effectiveDate: z.date(),
  isManualPaymentReceived: z.boolean().default(false),
});

export const tenantSchema = z.object({
  name: z
    .string()
    .min(1, { message: 'Required' })
    .max(250, { message: 'Maximum 250 Characters is allowed' }),
  billingContactFirstName: z
    .string()
    .max(50, { message: 'Maximum 50 Characters is allowed' })
    .optional()
    .or(z.literal('')),
  billingContactLastName: z
    .string()
    .max(50, { message: 'Maximum 50 Characters is allowed' })
    .optional()
    .or(z.literal('')),
  billingContactPhone: z
    .string()
    .refine((val) => isValidPhoneNumber(val), {
      message: 'Invalid Phone Number',
    })
    .optional()
    .or(z.literal('')),
  billingEmail: z
    .string()
    .max(100, { message: 'Maximum 100 Characters is allowed' })
    .email({ message: 'Wrong Format' })
    .optional()
    .or(z.literal('')),
  billingAddress: z
    .string()
    .max(500, { message: 'Maximum 500 Characters is allowed' })
    .optional()
    .or(z.literal('')),
  billingCity: z
    .string()
    .max(50, { message: 'Maximum 50 Characters is allowed' })
    .optional()
    .or(z.literal('')),
  billingStateProv: z
    .string()
    .max(50, { message: 'Maximum 50 Characters is allowed' })
    .optional()
    .or(z.literal('')),
  billingCountry: z
    .string()
    .max(50, { message: 'Maximum 50 Characters is allowed' })
    .optional()
    .or(z.literal('')),
  accountFirstName: z
    .string()
    .min(1, { message: 'Required' })
    .max(50, { message: 'Maximum 50 Characters is allowed' }),
  accountLastName: z
    .string()
    .min(1, { message: 'Required' })
    .max(50, { message: 'Maximum 50 Characters is allowed' }),
  accountEmail: z
    .string()
    .min(1, { message: 'Required' })
    .max(100, { message: 'Maximum 100 Characters is allowed' })
    .email({ message: 'Wrong Format' }),
  accountCellPhone: z
  .string()
  .refine((val) => isValidPhoneNumber(val), {
    message: 'Invalid Phone Number',
  })
  .optional()
  .or(z.literal('')),
  localShortName: z.string().max(200, { message: 'Maximum 200 Characters is allowed' }).optional(),
  localLongName: z.string().max(200, { message: 'Maximum 200 Characters is allowed' }).optional(),
  unionXAPIKey: z.string().max(200, { message: 'Maximum 200 Characters is allowed' }).optional(),
  unionXAPIURL: z.string().max(200, { message: 'Maximum 200 Characters is allowed' }).optional(),
  mapAPIKey:z.string().max(200, { message: 'Maximum 200 Characters is allowed' }).optional(),
  baseRoute: z
    .string()
    .min(1, { message: 'Required' })
    .max(200, { message: 'Maximum 200 Characters is allowed' })
    .regex(/^(http|https):\/\//, {
      message: 'Invalid URL',
    }),
  accountLimit: z
    .number()
    .gte(1, { message: 'Minimum value 1' })
    .lte(1000000, { message: 'Maximum limit 1000000' }),
  driveLimit: z
    .number()
    .gte(1, { message: 'Minimum value 1' })
    .lte(1000000, { message: 'Maximum limit 1000000' }),
  modules: z.string().optional().array().min(0),
});
